<template lang='pug'>
	.terms-and-conditions.constrainer
		.first-content
			Crumbs(slug='/terms-and-conditions')
			h1.title Terms and conditions
			.dashes
				.dash(v-for='n in 5')
			.terms-and-conditions-items(
				v-if="items.length"
			)
				.terms-and-conditions-item(
					v-for='item in items'
					:key='item.id'
				)
					p.para.fs.bold {{ item.title }}
					p.para.fs {{ item.text }}
</template>

<script>
// @ is an alias to /src
export default {
	name: 'TermsAndConditions',
	metaInfo: {
		title: 'Safe.is - Terms and conditions',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/terms-and-conditions/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/spring.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Terms and conditions'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: ''
			},
			{
				name: 'description',
				property: 'description',
				content: ''
			}
		]
	},
	data: function () {
		return {
			items: []
		};
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/termsAndConditions').then((result) => {
			this.items = result.data;
		});
	}
};
</script>

<style lang='less'>
.terms-and-conditions {
	margin-top: 20px;
	margin-bottom: 20px;
	.dashes {
		margin-top: 28px;
		margin-bottom: 37px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
	.first-content {
		margin-bottom: 100px;
		.para {
			max-width: 764px;
			&.bold {
				font-weight: bold;
			}
		}
	}
}
</style>
